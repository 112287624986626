<template>
    <v-snackbar :color="color" v-model="showSnackbar" top right>
      <div class="text-h6 mb-2" v-if="title !=''">{{ title }}</div>
      <v-icon v-if="icon != ''" class="mr-3">{{ icon }}</v-icon>
      {{ message }}
    </v-snackbar>
  </template>
  <script>
  export default {
    name: 'liToast',
    data() {
      return {
        showSnackbar: false,
        title: '',
        message: '',
        color: 'success',
        icon: 'mdi-check',
        timer: 5000
      }
    },
    methods: {
      show(data) {
        this.title = data.title || ''
        this.message = data.message || 'missing "message".'
        this.color = data.color || 'success'
        this.timer = data.timer || 2000
        this.icon = data.icon || ''
        this.showSnackbar = true
      }
    }
  }
  </script>
  