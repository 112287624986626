import axios from "axios";
const API_URL = process.env.VUE_APP_DEPLOYTEQ_API_URL;
const CLAIM_URL = process.env.VUE_APP_DEPLOYTEQ_CLAIM_URL;
class liApiService {
  login(_email) {
    const baseURI = `${API_URL}/login`;
    const body = { email: _email};
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, body, config)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  getRelations(_clangID) {
    const baseURI = `${API_URL}/all-relations`;
    const body = { clangID: _clangID};
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, body, config)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  getInvitations(_clangID) {
    const baseURI = `${API_URL}/customers-with-barcodes`;
    const body = { clangID: _clangID};
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, body, config)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  getTimeSlots(_clangID) {
    const baseURI = `${API_URL}/available-tickets`;
    const body = { clangID: _clangID};
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, body, config)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  updateRelation(_data){
    const baseURI = `${API_URL}/update-relation`;
    const payload = _data;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, payload, config)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  addRelation(_data){
    const baseURI = `${API_URL}/add-new-relation`;
    const payload = _data;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, payload, config)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  deleteRelation(_data){
    const baseURI = `${API_URL}/delete-relation`;
    const payload = _data;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, payload, config)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  sendInvite(_data){
    const baseURI = `${API_URL}/send-invite`;
    const payload = _data;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, payload, config)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  reSendInvite(_data){
    const baseURI = `${API_URL}/resend-invite`;
    const payload = _data;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, payload, config)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  claim_getTimeslots(_claim_getTimeslots){
    const baseURI = `${CLAIM_URL}/available-tickets`;
    const body = { clangID: _claim_getTimeslots};
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, body, config)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  claim_checkTicket(_barcode){
    const baseURI = `${CLAIM_URL}/is-ticket-available`;
    const body = { barcodeClangId: _barcode};
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, body, config)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
  claim_ticket(_data){
    const baseURI = `${CLAIM_URL}/send-confirmation`;
    const body = _data;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios
      .post(baseURI, body, config)
      .then(function (response) {
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.debug(error);
      });
  }
}
export default new liApiService();
