<template>
  <div v-if="storeLoading" class="text-center w-100 d-flex" style="height: 100vh">
    <v-progress-circular indeterminate color="primary" class="ma-auto" />
  </div>
</template>
<script>
export default {
  name: "liLoader",
  computed: {
    storeLoading() {
      return this.$store.getters["liApiStore/getStoreLoading"];
    },
  },
};
</script>
