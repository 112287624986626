import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// THIRD PARTY
// Page title 
import VuePageTitle from 'vue-page-title'
Vue.use(VuePageTitle, {
  // prefix: 'My App - ',
  suffix: ' | Events | Just Brands '
});
// Perfect ScrollBar
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
Vue.use(PerfectScrollbar)
// LINNOX
// Filter
import './filters/filters';
// Mixin
import liMixin from './mixins/liMixin';
Vue.mixin(liMixin)
// STATIC
// Navigation
import liNav from './components/static/nav';
Vue.component('liNav', liNav);
// LOADER
import liLoader from './components/static/loader';
Vue.component('liLoader', liLoader);
// TOAST
import liToast from './components/dynamic/toast';
Vue.component('liToast', liToast);
// RELATION MODAL
import liRelationModal from './components/dynamic/relationModal';
Vue.component('liRelationModal', liRelationModal);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
