import liApiService from "../services/liApi.service";
export const liApiStore = {
  namespaced: true,
  state: {
    relations: null,
    invitations: null,
    timeSlots: null,
    clangID: null,
    loading: true,
    userName: null,
    claim_timeSlots:null,
  },
  actions: {
    LOGIN({ commit }, _email) {
      return liApiService.login(_email).then(
        (response) => {
          commit("setLogin", response.data);
          return Promise.resolve(response.data.clangID);
        },
        (error) => {
          return Promise.resolve("error", error);
        }
      );
    },
    LOGOUT({ commit }) {
      const _data = { clangID: null, firstname: null };
      commit("setLogin",_data );
      return Promise.resolve();
    },
    GET_RELATIONS({ commit }, _clangID) {
      return liApiService.getRelations(_clangID).then(
        (response) => {
          commit("setRelations", response.data);
          return Promise.resolve();
        },
        (error) => {
          console.debug(error);
        }
      );
    },
    GET_INVITATIONS({ commit }, _clangID) {
      return liApiService.getInvitations(_clangID).then(
        (response) => {
          commit("setInvitations", response.data);
          return Promise.resolve();
        },
        (error) => {
          console.debug(error);
        }
      );
    },
    GET_TIMESLOTS({ commit }, _clangID) {
      return liApiService.getTimeSlots(_clangID).then(
        (response) => {
          commit("setTimeSlots", response.data);
          return Promise.resolve();
        },
        (error) => {
          console.debug(error);
        }
      );
    },
    UPDATE_RELATION({ commit }, _data) {
      return liApiService.updateRelation(_data).then(
        () => {
          commit("emptyFN");
          return Promise.resolve();
        },
        (error) => {
          console.debug(error);
        }
      );
    },
    ADD_NEW_RELATION({ commit }, _data) {
      return liApiService.addRelation(_data).then(
        () => {
          commit("emptyFN");
          return Promise.resolve();
        },
        (error) => {
          console.debug(error);
        }
      );
    },
    DELETE_RELATION({ commit }, _data) {
      return liApiService.deleteRelation(_data).then(
        () => {
          commit("emptyFN");
          return Promise.resolve();
        },
        (error) => {
          console.debug(error);
        }
      );
    },
    SET_STORE_LOADING({ commit }, _loading) {
      commit("setStoreLoading", _loading);
      return Promise.resolve();
    },
    SEND_INVITE({ commit }, _data) {
      return liApiService.sendInvite(_data).then(
        (response) => {
          console.log(response)
          commit("emptyFN");
          return Promise.resolve();
        },
        (error) => {
          console.debug(error);
        }
      );
    },
    RE_SEND_INVITE({ commit }, _data) {
      return liApiService.reSendInvite(_data).then(
        () => {
          commit("emptyFN");
          return Promise.resolve();
        },
        (error) => {
          console.debug(error);
        }
      );
    },
    CLAIM_GET_TIMESLOTS({ commit }, _claim_clangID) {
      return liApiService.claim_getTimeslots(_claim_clangID).then(
        (response) => {
          commit("claim_setTimeSlots", response.data);
          return Promise.resolve();
        },
        (error) => {
          console.debug(error);
        }
      );
    },
    CLAIM_CHECK_TICKET({ commit }, _barcode) {
      return liApiService.claim_checkTicket(_barcode).then(
        (response) => {
          commit("emptyFN");
          return Promise.resolve(response);
        },
        (error) => {
          console.debug(error);
        }
      );
    },
    CLAIM_TICKET({ commit }, _data) {
      return liApiService.claim_ticket(_data).then(
        (response) => {
          commit("emptyFN");
          return Promise.resolve(response);
        },
        (error) => {
          console.debug(error);
        }
      );
    },
  },
  mutations: {
    emptyFN() {
      // SILENCE IS GOLDEN
    },
    setRelations(state, _data) {
      state.relations = _data;
    },
    setInvitations(state, _data) {
      state.invitations = _data;
    },
    setTimeSlots(state, _data) {
      state.timeSlots = _data;
      state.loading = false;
    },
    setLogin(state, _data) {
      state.clangID = _data.clangID;
      state.userName = _data.firstname;
    },
    setStoreLoading(state, _loading) {
      state.loading = _loading;
    },
    claim_setTimeSlots(state, _data) {
      state.claim_timeSlots = _data;
      state.loading = false;
    },
  },
  getters: {
    getUserName: (state) => {
      return state.userName;
    },
    getClangID: (state) => {
      return state.clangID;
    },
    getStoreLoading: (state) => {
      return state.loading;
    },
    getRelations: (state) => {
      return state.relations;
    },
    getInvitations: (state) => {
      return state.invitations;
    },
    getTimeSlots: (state) => {
      return state.timeSlots;
    },
    getRelation: (state) => (id) => {
      if (state.relations != null) {
        if (state.relations != null) {
          return state.relations.find((item) => item.id === id);
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    claim_getTimeSlots: (state) => {
      return state.claim_timeSlots;
    },
  },
};
