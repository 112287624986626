<template>
  <div>
    <!-- ADMIN HEADER -->
    <template v-if="$route.meta.showAdminNav == true">
      <v-app-bar fixed dark color="primary" class="liNav" :class="{ scrolled: scrollPosition > 300, mobile: isAppMobile }">
        <!-- LOGO / DESKTOP -->
        <v-btn key="home-desktop" text class="liLogo dekstop mx-3" :to="`/${$route.params.code}/${$route.params.language}`" v-if="!isAppMobile">
          <v-img :width="120" :src="logoImageFull" />
        </v-btn>

        <!-- NAVIGATION / DESKTOP -->
        <template v-if="!isAppMobile && navigationContent != null && defaultTextContent != null">
          <!-- Navigation -->
          <template v-for="(nav, index) in navigationContent">
            <v-btn v-if="nav.status != 0" :disabled="(nav.url.includes('invite') || nav.url.includes('invitations')) && timeSlots.length == 0" :key="index" text class="justify-start" :class="nav.styleClasses" :to="`/${$route.params.code}${nav.url}`" block>
              <v-icon v-if="nav.icon != ''" small class="mr-3">
                {{ nav.icon }}
              </v-icon>
              {{ nav.title }}
            </v-btn>
          </template>
          <!-- Language selector -->
          <v-menu top right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark v-bind="attrs" v-on="on" class="liLanguage mx-3">
                <v-img v-if="language != null" :src="flags[language]" width="30px" height="20px;" />
                <v-img v-else :src="flags['nl']" width="30px" height="20px;" />
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item v-for="(item, i) in defaultTextContent.filterCountries" :key="i" :link="language != item.shortcode" :disabled="language == item.shortcode || item.disabled == true" :style="language == item.shortcode ? 'background-color:#f1f1f1;' : ''">
                <v-list-item-title @click.prevent="language = item.shortcode">
                  <v-img :src="flags[item.shortcode]" width="20px" height="15px;" style="display: inline-block; margin-right: 10px" />
                  {{ item.name }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <!-- LOGO / MOBILE -->
        <v-btn key="home-mobile" text class="liLogo mobile" :to="`/${$route.params.code}/${$route.params.language}`" :width="30" :height="30" v-else>
          <v-img :aspect-ratio="1 / 1" :src="logoImage" />
        </v-btn>
        <v-spacer />
        <!-- NAVIGATION / MOBILE -->
        <template v-if="isAppMobile && navigationContent != null && defaultTextContent != null">
          <v-btn color="white" elevation="2" class="mr-0" fab light small @click.stop="drawer = !drawer">
            <v-icon>{{ drawer ? "mdi-close" : "mdi-menu" }}</v-icon>
          </v-btn>
          <v-navigation-drawer v-model="drawer" absolute hide-overlay right height="calc(100vh - 56px)" style="top: 56px">
            <!-- Navigation -->
            <template v-for="(nav, index) in navigationContent">
              <v-btn v-if="nav.status != 0" :key="index" text class="justify-start" :class="nav.styleClasses" :to="`/${$route.params.code}${nav.url}`" block>
                <v-icon v-if="nav.icon != ''" small class="mr-3">
                  {{ nav.icon }}
                </v-icon>
                {{ nav.title }}
              </v-btn>
            </template>
            <!-- Language selector -->
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark v-bind="attrs" v-on="on" class="liLanguage mx-3">
                  <v-img v-if="language != null" :src="flags[language]" width="30px" height="20px;" />
                  <v-img v-else :src="flags['nl']" width="30px" height="20px;" />
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(item, i) in defaultTextContent.filterCountries" :key="i" :link="language != item.shortcode" :disabled="language == item.shortcode || item.disabled == true" :style="language == item.shortcode ? 'background-color:#f1f1f1;' : ''">
                  <v-list-item-title @click.prevent="language = item.shortcode">
                    <v-img :src="flags[item.shortcode]" width="20px" height="15px;" style="display: inline-block; margin-right: 10px" />
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-navigation-drawer>
        </template>
      </v-app-bar>
    </template>

    <!-- CLAIM HEADER -->
    <template v-if="$route.meta.showAdminNav == false">
      <v-app-bar fixed dark color="primary" class="liNav claim">
        <!-- LOGO / DESKTOP -->
        <v-btn key="home-desktop-claim" text disabled class="liLogo dekstop ma-auto" v-if="!isAppMobile">
          <v-img :width="200" :src="logoFullMonstersale" />
        </v-btn>
        <!-- LOGO / MOBILE -->
        <v-btn key="home-mobile-claim" text disabled class="liLogo mobile mx-auto" :width="170" v-if="isAppMobile">
          <v-img :width="80" :src="logoFullMonstersale" />
        </v-btn>
      </v-app-bar>
    </template>
    <!-- VERSION -->
    <div class="version"> v. {{version}}</div>
  </div>
</template>
<script>
const VERSION = process.env.VUE_APP_VERSION;
import logo from "../../assets/images/logo.svg";
import logoFull from "../../assets/images/logo_full.svg";
import logoFullMonstersale from "../../assets/images/logo_monstersale_full.svg";
export default {
  name: "liNav",
  data() {
    return {
      version:VERSION,
      drawer: false,
      logoImage: logo,
      logoImageFull: logoFull,
      logoFullMonstersale: logoFullMonstersale,
    };
  },
  computed: {
    clangID() {
      return this.$store.getters["liApiStore/getClangID"];
    },
  },
};
</script>
<style lang="scss">

</style>
