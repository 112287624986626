<template>
  <v-container class="bg-light pa-10" fluid fill-height>
    <template v-if="defaultTextContent != null && pageContent != null">
      <div class="w-100 align-self-start">
        <h1 v-if="!isAppMobile" class="text-h3 font-weight-bold">{{ pageContent.title }} {{ userName }}!</h1>
        <h1 v-else style="line-height: 1">
          <span class="body-1 mb-0">
            {{ pageContent.title }}
          </span>
          <br />
          <span class="text-h3 font-weight-bold"> {{ userName }}!</span>
        </h1>
        <template v-if="timeSlots.length > 0">
          <v-container fluid class="px-0 mt-3">
            <ul class="liGridWrapper">
              <template v-for="(timeSlot, index) in timeSlots">
                <li class="" :key="index">
                  <v-card class="pa-3">
                    <h2 class="text-h6 font-weight-bold mb-1">{{ pageContent.timeSlot }} {{ timeSlot.time_slot }}</h2>
                    <div class="body-2 mb-3">
                      {{ capitalizeFirstLetter(localeDateFormat(timeSlot.time_slot_date, "manual", "long")) }}<br />
                      {{ timeSlot.time_slot_time }}<br />
                    </div>
                    <div class="body-4 mt-4 mb-3 grey--text">
                      {{ pageContent.donwloadedTickets }}
                    </div>
                    <v-progress-linear :value="parseInt(Math.round((timeSlot.tickets_claimed / timeSlot.tickets_total) * 100))" class="w-100"></v-progress-linear>
                    <div class="text-right body-4">{{ timeSlot.tickets_claimed }} / {{ timeSlot.tickets_total }}</div>
                  </v-card>
                </li>
              </template>
            </ul>
          </v-container>
          <v-container fluid class="px-0 mt-3">
            <div class="">{{pageContent.timeSlotText }}</div>
            <div class="w-100 flex-column my-3" :class="{ 'd-block': isAppMobile, 'd-flex': !isAppMobile }">
              <template v-for="r in defaultTextContent.relationTypes">
                <div :key="r.value" class="body-3 py-2 pl-1 pr-8" :class="{ 'd-block': isAppMobile, 'd-flex': !isAppMobile }">
                  <div :style="isAppMobile ? '' : 'min-width: 280px'">
                    <v-chip small>{{ r.name }}</v-chip>
                  </div>
                  <span class="grey--text"> {{ defaultTextContent.accesToTimeSlotLabel }} </span>
                  <b class="ml-3 float-end"> {{ r.accesToTimeSlotsLabel }}</b>
                </div>
              </template>
            </div>
          </v-container>
        </template>
        <template v-else-if="timeSlots.length == 0">
          <h2 class="mt-5 mb-3" v-html="pageContent.noTimeSlotsTitle" />
          <div v-html="pageContent.noTimeSlots" />
        </template>
      </div>
    </template>
    <template v-else>
      <div class="d-flex w-100" style="height: 50vh">
        <v-progress-circular indeterminate color="primary" class="ma-auto" />
      </div>
    </template>
  </v-container>
</template>

<script>
const API_URL = process.env.VUE_APP_CONTENT_API_URL;
export default {
  title: "",
  name: "Home",
  data() {
    return {
      apiPublicUrl: API_URL,
    };
  },
  created() {
    this.fetchURL(`/${this.language}`);
  },
  watch: {
    page(val) {
      if (val != null) {
        this.$title = this.page.pagetitle;
      }
    },
  },
  mounted() {
    console.log(`START -- ${this.$options.name} --  VIEW`);
    console.log("language", this.language);
    console.log("website", this.website);
    console.log("fullUrl", this.fullUrl);
    console.log("END -- HOME VIEW");
    if (this.website != null) {
      this.fetchPageData();
    }
  },
  computed: {
    userName() {
      return this.$store.getters["liApiStore/getUserName"];
    },
    clangID() {
      return this.$store.getters["liApiStore/getClangID"];
    },
    storeLoading() {
      return this.$store.getters["liApiStore/getStoreLoading"];
    },
    loggedInUser() {
      return this.$store.getters["liApiStore/getRelation"](this.clangID);
    },
    relations() {
      return this.$store.getters["liApiStore/getRelations"];
    },
    invites() {
      return this.$store.getters["liApiStore/getInvites"];
    },
    timeSlots() {
      return this.$store.getters["liApiStore/getTimeSlots"];
    },
    page() {
      return this.$store.getters["liApiContent/getPage"](this.fullUrl);
    },
    pageContent() {
      return this.page != null ? JSON.parse(this.page.content) : null;
    },
  },
  methods: {},
};
</script>
