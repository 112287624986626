<template>
  <v-app :class="$route.meta.showAdminNav == true ? ` ${liStripString($route.name)} portal ` : liStripString($route.name)">
    <template v-if="$route.name != 'Referral' && $route.name != 'NotAllowed'">
      <liNav />
      <liToast ref="liToast" />
      <liRelationModal ref="liRelationModal" />
    </template>
    <v-main class="liMain">
      <liLoader />
      <transition name="fade" mode="out-in">
        <router-view :key="`${$route.name}-${$route.params.language}`" />
      </transition>
    </v-main>
  </v-app>
</template>
<script>
const PUBLIC_WEBSITE = process.env.VUE_APP_WEBSITE;
export default {
  name: "EventsApp",
  data: () => ({
    host: null,
  }),
  created() {
    this.$store.commit("liApiContent/setWebsite", PUBLIC_WEBSITE);
    this.host = location.hostname;
  },
  watch: {
    language(_val) {
      if(_val != undefined && _val != null){
        this.setup();
      }
    },
  },
  mounted() {
    this.setup();
  },
  activated() {
    this.setup();
  },
  methods: {
    setup() {
      
      this.fetchNavigationItems();
      this.fetchFooterItems();
      this.fetchAppDefaultTexts();
    },
  },
};
</script>
<style lang="scss">
@import "./scss/index.scss";
</style>
