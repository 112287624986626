<template>
  <v-dialog v-model="showModal" width="80%" persistent max-width="500">
    <v-card>
      <!-- http://localhost:8081/bf80190ac09d4559e36191ba8e91a232a8788bcf49d6bfa0642ae1399b56ac8d/nl/relations -->
      <v-form ref="form" v-model="valid"  lazy-validation>
        <v-card-title class="text-h6" :color="color"> {{ title }}</v-card-title>
        <v-card-text v-if="type == 'edit' || type == 'new'" class="py-3 bg-white">
          <v-text-field v-model="relation.first_name" :rules="nameRules" :label="labels.firstName" required />
          <v-text-field v-model="relation.last_name" :rules="lastNameRules" :label="labels.lastName" required />
          <v-text-field v-model="relation.email" :rules="emailRules" :label="labels.email" required/>
          <v-select chips v-model="relation.relationship_type_id" item-text="name" item-value="valueString" :items="defaultTextContent.relationTypes" :label="labels.relationType" @change="updateLabel()" />
        </v-card-text>
        <v-card-actions class="bg-white pb-6">
          <v-spacer></v-spacer>
          <v-btn v-if="labels != null && labels.cancelText != ''" text @click.prevent="cancel">{{ labels.cancelText }}</v-btn>
          <v-btn v-if="labels != null" :disabled="!valid" color="primary" elevation="0" @click.prevent="save">{{ labels.saveText }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "liRelationModal",
  data() {
    return {
      valid: true,
      showModal: false,
      title: "",
      type: "",
      color: "",
      cancelText: "",
      saveText: "",
      relation: null,
      labels: null,
      nameRules: [(v) => !!v || this.liFormatText(this.defaultTextContent.requiredField, [this.labels.firstName.replace("*", "")])],
      lastNameRules: [(v) => !!v || this.liFormatText(this.defaultTextContent.requiredField, [this.labels.lastName.replace("*", "")])],
      emailRules: [
        (v) => !!v || this.liFormatText(this.defaultTextContent.requiredField, [this.labels.email.replace("*", "")]),
        (v) => {
          //  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
          return pattern.test(v) || this.defaultTextContent.emailRequiredField;
        //   // return /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/.test(v) || this.defaultTextContent.emailRequiredField;
        //   // return /^.+@[^.].*.[a-z]{2,}$/.test(v) || this.defaultTextContent.emailRequiredField;
        //   // return /^[A-Za-z0-9](([_.-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([.-]?[a-zA-Z0-9]+)*).([A-Za-z]{2,})$/.test(v) || this.defaultTextContent.emailRequiredField;
        //   // return /^[A-Za-z0-9](([_.-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([.-]?[a-zA-Z0-9]+)*)+\.([A-Za-z]{2,})$/.test(v) || this.defaultTextContent.emailRequiredField;
        //   // return /^[A-Za-z0-9](([_.-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([.-]?[a-zA-Z0-9]+)*)+\.([A-Za-z]{2,})$/.test(v) || this.defaultTextContent.emailRequiredField;
        //   // return /^[a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$/.test(v) || this.defaultTextContent.emailRequiredField;
        //   // return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+\.([A-Za-z]{2,})$/.test(v) || this.defaultTextContent.emailRequiredField;
        //   // console.log(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v));
        //   // if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w {2, 3})+$/.test(v)) {
        //   //   return v;
        //   // } else {
        //   //   [this.defaultTextContent.emailRequiredField]
        //   // }
        //   // return  || this.defaultTextContent.emailRequiredField;
          // if (v != null && v != undefined && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) return true;
          // return this.defaultTextContent.emailRequiredField;
        },
      ],
    };
  },
  methods: {
    open(data) {
      if (this.$refs.form) this.$refs.form.resetValidation();
      this.title = data.title || "";
      this.type = data.type || "edit";
      this.color = data.color || "";
      this.cancelText = data.cancelText || "";
      this.saveText = data.saveText || "save";
      this.relation = data.relation;
      this.labels = data.labels;
      this.updateLabel();
      this.showModal = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    updateLabel() {
      this.relation.relationship_type = this.getRelationTypeLabel(this.relation.relationship_type_id);
    },
    save() {
      if (this.$refs.form.validate()) {
        this.$refs.form.resetValidation();
        this.resolve(this.relation);
        this.showModal = false;
      }
    },
    cancel() {
      this.$refs.form.resetValidation();
      this.resolve(false);
      this.showModal = false;
    },
  },
};
</script>
