import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:language/notallowed",
    name: "NotAllowed",
    component: () => import(/* webpackChunkName: "PageNotFound" */ "../views/NotAllowed.vue"),
    meta: { showAdminNav: false },
  },
  {
    path: "/:language/claim-tickets",
    name: "ClaimTickets",
    component: () => import(/* webpackChunkName: "PageNotFound" */ "../views/Claim.vue"),
    meta: { showAdminNav: false },
  },
  {
    path: "/referral/:identifier?",
    name: "Referral",
    component: () => import(/* webpackChunkName: "PageNotFound" */ "../views/Referral.vue"),
    meta: { showAdminNav: false },
  },
  {
    path: "/:code/:language",
    name: "Home",
    component: Home,
    meta: { showAdminNav: true },
  },
  {
    path: "/:code/:language/relations",
    name: "Relations",
    component: () => import(/* webpackChunkName: "PageNotFound" */ "../views/Relations.vue"),
    meta: { showAdminNav: true },
  },
  {
    path: "/:code/:language/invitations",
    name: "Invitations",
    component: () => import(/* webpackChunkName: "PageNotFound" */ "../views/Invitations.vue"),
    meta: { showAdminNav: true },
  },
  {
    path: "/:code/:language/invite",
    name: "Invite",
    component: () => import(/* webpackChunkName: "PageNotFound" */ "../views/Invite.vue"),
    meta: { showAdminNav: true },
  },
  {
    path: "*",
    name: "404",
    component: () => import(/* webpackChunkName: "PageNotFound" */ "../views/PageNotFound.vue"),
    meta: { showAdminNav: false },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
// eslint-disable-next-line
router.beforeEach((to, from, next) => {
  if (to.path == "/en/notAllowed") {
    next();
  } else if ("code" in to.params) {
    let clangId = Store.getters["liApiStore/getClangID"];
    if (clangId == null) {
      Store.dispatch("liApiStore/LOGIN", to.params.code).then((_clangID) => {
        if (_clangID == undefined) {
          Store.dispatch("liApiStore/LOGOUT");
          next("/en/notAllowed");
        } else {
          Store.dispatch("liApiStore/GET_RELATIONS", _clangID).then(() => {
            Store.dispatch("liApiStore/GET_INVITATIONS", _clangID).then(() => {
              Store.dispatch("liApiStore/GET_TIMESLOTS", _clangID).then(() => {
                next();
              });
            });
          });
        }
      });
    } else {
      next();
    }
  } else if (to.name == "ClaimTickets") {
    let claim_timeSlots = Store.getters["liApiStore/claim_getTimeSlots"];
    let claim_barcode = to.query.barcode != undefined && to.query.barcode != "" ? to.query.barcode.split("-")[1] : null;
    if (claim_timeSlots == null) {
      Store.dispatch("liApiStore/CLAIM_GET_TIMESLOTS", claim_barcode).then(() => {
        next();
      });
    } else {
      next();
    }
  } else {
    console.log("LogoOut");
    Store.dispatch("liApiStore/LOGOUT");
    next("/en/notAllowed");
  }
});

export default router;
